import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import $ from "jquery";

import { listSubClients } from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";

import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitleBreadCrumb from "../ExtraComponents/PageTitleBreadCrumb";
import HelperClass from "./../../../config/helperClass";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  ListSubClientAssignRoles,
  editSubClientAssignRole,
} from "./../../../config/client_role_api_calls";
import {
  getSubClientIntegrationData,
  updateSubClientPremission,
  updateSubClientPremissionMeta,
  sendSubClientInvite,
  deleteInviteSubClient,
  resendActivationSub,
  clientLoginSso
} from "./../../../config/api_calls";
import { getIntegration } from "./../../../config/reseller_api_calls";

import localization from "../../../localization/localization";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { ValidateEmail } from "../../../config/utility";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();
class Subclients extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      teamsArray: [],
      tfnTermArray: [],
      azTermArray: [],
      cnamArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      cnam_service: "",
      liveagent_service: "",

      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      emptyService: 0,
      all_client_data: [],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      client_accountno: "",
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",

      va_intergation: "No",
      isEnableChecked: "",
      subClientIntegrationData: [],

      meta_intergation: "No",
      isEnableCheckedMeta: "",

      clientErrorMessage: "",
      clientSuccessMessage: "",
      main_client_name: "",
      client_name: "",
      client_email: "",
      invitation_comment: "",
      tableData_invitedClients: [],
      sub_activeTab: localStorage.getItem("sub_activeTab") || "tabItem8",

      loginErrorMessage: "",
      login_url: "",

      columnServices: [
        { name: "NAME" },
        { name: "COMPANY" },
        {
          name: "PHONE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setPhone(value, tableMeta)}</div>;
            },
          },
        },
        { name: "EMAIL" },

        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "ACCOUNTNO", options: { display: false } },
        { name: "VERIFIED", options: { display: false } },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subclientAssignRole(value, tableMeta)
                                  }
                                  title="Assign Role"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Role</span>
                                </a>
                              </li>
                              {(this.state.va_intergation === "Yes" ||
                                this.state.meta_intergation === "Yes") && (
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      onClick={() =>
                                        this.clientIntegrationPer(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      title="Assign Role"
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                          this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-exchange"></em>
                                      <span>Manage Integration</span>
                                    </a>
                                  </li>
                                )}
                              {tableMeta.rowData && tableMeta.rowData[7] == "No" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.resendActivation(value, tableMeta)
                                    }
                                    title="Assign Role"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-grid-c"></em>
                                    <span>Resend Activation</span>
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => this.clientLoginSso(value, tableMeta)}
                                  title="Dashboard"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em
                                    className="icon ni ni-signin"
                                  ></em>
                                  <span>Dashboard</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnServices_invitedClients: [
        { name: "CLIENT NAME" },
        { name: "CLIENT EMAIL" },
        { name: "AGENT NAME" },
        { name: "AGENT EMAIL" },
        {
          name: "INVITED ON",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
            filter: false,
          },
        },
        { name: "AGENT ID", options: { display: false } },
        { name: "ID", options: { display: false } },
        {
          name: "Comment",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div onClick={() => this.assignedTo(value, tableMeta)}>
                  <span
                    className="badge badge-outline-secondary"
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </span>
                </div>
              );
            },
          },
        },
        { name: "PARENT_ACCOUNTNO", options: { display: false } },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[6])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Client</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.resendInvite(
                                      tableMeta.rowData[1],
                                      tableMeta.rowData[0],
                                      tableMeta.rowData[7],
                                      tableMeta.rowData[8]
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Resend Activation"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Resend Invite</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    let client_accountno = this.props.props.match.params.id;
    this.setState({
      client_accountno: client_accountno,
    });
    const clientsResponce = await listSubClients(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );

    console.log("Client Data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        all_client_data: clientsResponce.data.all_data,
        main_client_name: clientsResponce.data.mainClientInfo.name,
        tableData_invitedClients: clientsResponce.data.invited_clients,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
    this.get_integration_data();
  }

  subclientAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[6];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      subclientRoleLoader: true,
      subdisableRoleBtn: false,
      subdisableSubRoleBtn: false,
      rolesSubClientName:
        tableMeta.rowData[0] + " (" + tableMeta.rowData[1] + ")",
      checkedName: tableMeta.rowData[2],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalSubClientAssignRole").modal("show");
    this.getSubClientRoleData(agentId, agentEmail);
  };

  getSubClientRoleData = async (agentId, agentEmail) => {
    const listSubClientAssignRoles = await ListSubClientAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubClientRoles Res: ", listSubClientAssignRoles.data.data);
    if (
      listSubClientAssignRoles.data.status === 403 ||
      listSubClientAssignRoles.data.errors === "authentication missing" ||
      listSubClientAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubClientAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubClientAssignRoles.data.status === 200 &&
      listSubClientAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listSubClientAssignRoles.data.data.roles;
      const assignSubRoleData = listSubClientAssignRoles.data.data.subroles;
      const allRoleData = listSubClientAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listSubClientAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        subclientRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  handleSubmitRoleCheckboxSub = async (name, email) => {
    this.setState({
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subdisableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    const saveResponce = await editSubClientAssignRole(
      auth.getAccount(),
      auth.getToken(),
      CheckRoleFields,
      CheckSubRoleFields,
      name,
      email
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      // window.location.replace("/error");
      this.setState({
        errorMessageSubClientRole:
          "There is some error while updating the Sub Client Role.",
        successMessageSubClientRole: "",
        subdisableRoleBtn: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageSubClientRole: "",
        successMessageSubClientRole: "Sub Client Role updated successfully.",
        subdisableRoleBtn: false,
      });
      setTimeout(() => {
        //this.props.props.history.push("/agent_roles");
        window.$("#modalSubClientAssignRole").modal("hide");
      }, 3000);
    } else {
      this.setState({
        errorMessageSubClientRole: saveResponce.data.message,
        successMessageSubClientRole: "",
        subdisableRoleBtn: false,
      });
      //window.location.replace("/error");
    }
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  subchangedRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subassignRoleData, subassignSubRoleData } = this.state;

    // Find the main role in assignRoleData
    const mainRole = subassignRoleData.find(
      (item) => item.id === parseInt(name)
    );

    if (!mainRole) {
      return;
    }

    const updatedChangedRolesRights = this.state.subchangedRolesRights.map(
      (item) =>
        item.id === mainRole.id ? { ...item, isChecked: checked } : item
    );

    if (!updatedChangedRolesRights.some((item) => item.id === mainRole.id)) {
      updatedChangedRolesRights.push({ ...mainRole, isChecked: checked });
    }

    const associatedSubRoles = subassignSubRoleData.filter(
      (subRole) => subRole.parent_role_id === mainRole.parent_role_id
    );

    const updatedSubRolesRights = this.state.subchangedSubRolesRights.map(
      (subRole) => {
        const isAssociated = associatedSubRoles.some(
          (assocSubRole) => assocSubRole.id === subRole.id
        );
        if (isAssociated) {
          return { ...subRole, isChecked: checked };
        } else {
          return subRole;
        }
      }
    );

    this.setState({
      subchangedRolesRights: updatedChangedRolesRights,
      subchangedSubRolesRights: updatedSubRolesRights,
    });
  };

  subchangedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedSubRolesRights } = this.state;

    const index = subchangedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...subchangedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedSubRolesRights: [
          ...prevState.subchangedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  addMenuHistoryLeft = (link) => {
    this.props.props.history.push(link);
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
  };

  /*clientIntegrationPer = async (value, tableMeta) => {
  let client_name = tableMeta.rowData[0];
  let client_comp = tableMeta.rowData[1];
  let client_accountno = tableMeta.rowData[6];
  this.setState({
    client_name: client_name,
    client_comp: client_comp,
    client_accountno: client_accountno,
  });

  const clientsResponce = await getSubClientIntegrationData(
    auth.getAccount(),
    auth.getToken(),
    client_accountno
  );

  console.log("clientsResponce Data: ", clientsResponce.data);
  if (
    clientsResponce.data.status === 403 ||
    clientsResponce.data.errors === "authentication missing" ||
    clientsResponce.data.errors === "jwt expired"
  ) {
    auth.logout();
  } else if (
    clientsResponce.data.status === 404 ||
    clientsResponce.data.note === "missing"
  ) {
    // window.location.replace("/error");
    console.log("settings missing");
    this.setState({
      tableLoader: false,
    });
  } else if (
    clientsResponce.data.status === 200 &&
    clientsResponce.data.message === "success"
  ) {
    if (
      clientsResponce.data.subClientPermission &&
      clientsResponce.data.subClientPermission.length > 0
    ) {
      const subClientData = clientsResponce.data.subClientPermission[0];

      let isEnableChecked = false;

      const subClientDataMeta = subClientPermissionMeta?.length > 0 ? subClientPermissionMeta[0] : {};

      const isEnableCheckedMeta = subClientDataMeta.enable === "Yes";

      if (subClientData.enable === "Yes") {
        isEnableChecked = true;
      }

      this.setState({
        subClientIntegrationData: subClientData,
        isEnableChecked: isEnableChecked,
        status: isEnableChecked ? "enable" : "disable",
        isEnableCheckedMeta,
        statusMeta: isEnableCheckedMeta ? "enable" : "disable",
        tableLoader: false,
      });
    }
  } else {
    this.setState({
      tableLoader: false,
    });
    // window.location.replace("/error");
  }

  window.$("#modalAgentAssignRole").modal("show");
};*/

  clientIntegrationPer = async (value, tableMeta) => {
    const client_name = tableMeta.rowData[0];
    const client_comp = tableMeta.rowData[1];
    const client_accountno = tableMeta.rowData[6];

    this.setState({
      client_name,
      client_comp,
      client_accountno,
    });

    const clientsResponse = await getSubClientIntegrationData(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );

    console.log("clientsResponse Data: ", clientsResponse.data);

    const {
      status,
      errors,
      note,
      subClientPermission,
      subClientPermissionMeta,
    } = clientsResponse.data;

    if (
      status === 403 ||
      errors === "authentication missing" ||
      errors === "jwt expired"
    ) {
      auth.logout();
    } else if (status === 404 || note === "missing") {
      console.log("settings missing");
      this.setState({ tableLoader: false });
    } else if (status === 200 && clientsResponse.data.message === "success") {
      const subClientData =
        subClientPermission?.length > 0 ? subClientPermission[0] : {};
      const subClientDataMeta =
        subClientPermissionMeta?.length > 0 ? subClientPermissionMeta[0] : {};

      const isEnableChecked = subClientData.enable === "Yes";
      const isEnableCheckedMeta = subClientDataMeta.enable === "Yes";

      this.setState({
        subClientIntegrationData: subClientData,
        isEnableChecked,
        isEnableCheckedMeta,
        status: isEnableChecked ? "enable" : "disable",
        statusMeta: isEnableCheckedMeta ? "enable" : "disable",
        tableLoader: false,
      });
    } else {
      console.log("An unexpected error occurred.");
      this.setState({ tableLoader: false });
      // window.location.replace("/error"); // Uncomment if you want to redirect on error
    }

    window.$("#modalAgentAssignRole").modal("show");
  };

  updateSubClientPremissionMeta = async () => {
    const { client_accountno, statusMeta } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      disabled: true,
    });

    const servicesResponce = await updateSubClientPremissionMeta(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      statusMeta
    );
    console.log("updateSubClientPremission.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Permission updated successfully",
        isApproveActive: false,
      });
    } else {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleEnableChangeMeta = () => {
    this.setState(
      (prevState) => {
        const newStatusMeta = prevState.isEnableCheckedMeta
          ? "disable"
          : "enable";
        return {
          isEnableCheckedMeta: !prevState.isEnableCheckedMeta,
          statusMeta: newStatusMeta,
        };
      },
      () => {
        console.log(`StatusMeta changed to: ${this.state.statusMeta}`);
      }
    );
  };

  get_integration_data = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getIntegration(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_integration_data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let va_intergation = "No";
      let meta_intergation = "No";
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          console.log(
            'servicesResponce.data.data[i]["integration_type"]' +
            servicesResponce.data.data[i]["active"] +
            " " +
            servicesResponce.data.data[i]["integration_type"] +
            " " +
            servicesResponce.data.data[i]["status"]
          );
          if (
            servicesResponce.data.data[i]["integration_type"] ===
            "voicealerts" &&
            servicesResponce.data.data[i]["active"] === 1 &&
            servicesResponce.data.data[i]["status"] === "Yes"
          ) {
            va_intergation = "Yes";
          }
          if (
            servicesResponce.data.data[i]["integration_type"] === "metabase" &&
            servicesResponce.data.data[i]["active"] === 1 &&
            servicesResponce.data.data[i]["status"] === "Yes"
          ) {
            meta_intergation = "Yes";
          }
        }
      }
      console.log("va_intergation: ", va_intergation);
      this.setState({
        va_intergation: va_intergation,
        meta_intergation: meta_intergation,
      });
    } else {
    }
  };

  handleEnableChange = () => {
    this.setState(
      (prevState) => {
        const newStatus = prevState.isEnableChecked ? "disable" : "enable";
        return {
          isEnableChecked: !prevState.isEnableChecked,
          status: newStatus,
        };
      },
      () => {
        console.log(`Status changed to: ${this.state.status}`);
      }
    );
  };

  updateSubClientPremission = async () => {
    const { client_accountno, status } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      disabled: true,
    });

    const servicesResponce = await updateSubClientPremission(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      status
    );
    console.log("updateSubClientPremission.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Permission updated successfully",
        isApproveActive: false,
      });
    } else {
      this.setState({
        errorMessage:
          "There is an error while updating the permission. Please try again.",
      });
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  setPhone = (value, tableMeta) => {
    if (tableMeta.rowData[2] !== "") {
      let phone = this.formatPhoneNumber(tableMeta.rowData[2]);
      return phone;
    } else {
      return "---";
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  setNewClientSettings = async () => {
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      client_name: "",
      client_email: "",
      invitation_comment: "",
    });
    window.$("#modalAddNewClient").modal("show");
  };

  saveNewClient = async () => {
    const { client_name, client_email, invitation_comment, client_accountno } =
      this.state;
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      disabled: true,
    });

    if (client_name === "") {
      this.setState({
        clientErrorMessage: "Please enter the name.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (client_email === "") {
      this.setState({
        clientErrorMessage: "Please enter the email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (ValidateEmail(client_email) === false) {
      this.setState({
        clientErrorMessage: "Please enter the valid email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (invitation_comment === "") {
      this.setState({
        clientErrorMessage: "Please enter the comment.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendSubClientInvite(
        auth.getAccount(),
        auth.getToken(),
        client_email,
        client_name,
        invitation_comment,
        client_accountno
      );

      console.log("agentsResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          clientErrorMessage: "",
          clientSuccessMessage: "Invite successfully sent to the sub client.",
          disabled: false,
          client_email: "",
          client_name: "",
          tableData_invitedClients: servicesResponce.data.invited_clients,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        clientErrorMessage: "",
        clientSuccessMessage: "",
      });
    }, 4000);
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  handleTabClick = (tabId) => {
    this.setState({ sub_activeTab: tabId });
    localStorage.setItem("sub_activeTab", tabId);
  };

  assignedTo = (value, tableMeta) => {
    let invitation_comment = tableMeta.rowData[7];
    this.setState(
      {
        invitation_comment: invitation_comment,
      },
      () => {
        window.$("#modalInvitationComment").modal("show");
      }
    );
  };

  resendInvite = async (
    client_email,
    client_name,
    invitation_comment,
    client_accountno
  ) => {
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await sendSubClientInvite(
      auth.getAccount(),
      auth.getToken(),
      client_email,
      client_name,
      invitation_comment,
      client_accountno
    );

    window.$("#modalresendInvitation").modal("show");

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        clientErrorMessage: "Error sending invitation email.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState(
        {
          clientErrorMessage: "",
          clientSuccessMessage: "Invitation email sent successfully.",
          disabled: false,
        },
        () => {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        clientErrorMessage: "Error Sending Activation Email",
        clientSuccessMessage: "",
        disabled: false,
      });
    }
  };

  modalHideResend = () => {
    window.$("#modalresendInvitation").modal("hide");
  };

  deleteAlert = async (value) => {
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  deleteInviteClient = async () => {
    let client_id = this.state.delete_id;

    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      disabled: true,
    });

    const userResponce = await deleteInviteSubClient(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    console.log("deleteClientInvite: ", userResponce.data);

    if (
      userResponce.data.status === 403 ||
      userResponce.data.errors === "authentication missing" ||
      userResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      userResponce.data.status === 404 &&
      userResponce.data.message === "fail" &&
      userResponce.data.note !== ""
    ) {
      this.setState({
        clientErrorMessage: "Error deleting the invited client.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (
      userResponce.data.status === 200 &&
      userResponce.data.message === "success"
    ) {
      this.setState({
        clientErrorMessage: "",
        clientSuccessMessage: "Invited Client deleted successfully.",
        disabled: false,
      });
      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
    } else {
      this.setState({
        clientErrorMessage: "Error deleting the invited client.",
        clientSuccessMessage: "",
        disabled: false,
      });
    }
  };

  resendActivation = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[6];
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivationSub(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("resendActivation: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 3000);
  };

  clientLoginSso = async (value, tableMeta) => {
    const client_accountno = tableMeta.rowData[6];
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await clientLoginSso(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  render() {
    const breadcrumb_arr = [
      {
        title: "Clients",
        url: "/clients",
      },
      {
        title: "Sub Clients",
        url: "",
      },
    ];
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
            this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
            this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitleBreadCrumb
                        name={breadcrumb_arr}
                        icon="icon ni ni-users"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setNewClientSettings()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;
                              {localization.clientsListing.inviteButtonSub}
                            </button>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a
                              href={`/clients/Addsubclient/${this.state.client_accountno}`}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;
                              {localization.clientsListing.addNewButtonSub}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div class="modal fade" tabindex="-1" id="modalAlertResend">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal">
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-body modal-body-lg text-center">
                      <div class="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 class="nk-modal-title">Sending!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 class="nk-modal-title">Congratulations!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 class="nk-modal-title">Oops!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div class="modal-footer bg-lighter">
                      <div class="text-center w-100">
                        <a
                          href="#"
                          class="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalClientDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-card-view"></em> Extra
                          Details of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {this.state.kycErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.kycErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kycSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.kycSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key === "" ? (
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                              <div className="nk-help">
                                <div className="nk-help-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 90 90"
                                  >
                                    <rect
                                      x="5"
                                      y="7"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="25"
                                      y="27"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="15"
                                      y="17"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#fff"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <line
                                      x1="15"
                                      y1="29"
                                      x2="75"
                                      y2="29"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeMiterlimit="10"
                                      strokeWidth="2"
                                    ></line>
                                    <circle
                                      cx="53"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="60"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="67"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <rect
                                      x="22"
                                      y="39"
                                      width="20"
                                      height="20"
                                      rx="2"
                                      ry="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <circle
                                      cx="32"
                                      cy="45.81"
                                      r="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></circle>
                                    <path
                                      d="M29,54.31a3,3,0,0,1,6,0"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></path>
                                    <line
                                      x1="49"
                                      y1="40"
                                      x2="69"
                                      y2="40"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="51"
                                      x2="69"
                                      y2="51"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="57"
                                      x2="59"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="57"
                                      x2="66"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="46"
                                      x2="59"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="46"
                                      x2="66"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                  </svg>
                                </div>
                                <div className="nk-help-text">
                                  <h5>Initiate KYC Process</h5>
                                  <p className="text-soft">
                                    Register your customer with KYC to complete
                                    their profile. Click on "Initiate KYC"
                                    button. An email with KYC registration link
                                    will be sent to the customer.
                                  </p>
                                </div>
                                <div className="nk-help-action">
                                  {this.state.loading_button === false ? (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.initiateKyc();
                                      }}
                                      disabled={this.state.disabled}
                                      href="#"
                                      className="btn btn-lg btn-outline-primary"
                                    >
                                      <em className="icon ni ni-file-docs"></em>{" "}
                                      Initiate KYC
                                    </a>
                                  ) : null}
                                  {this.state.loading_button === true ? (
                                    <button
                                      className="btn btn-primary btn-lg"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Initiating... </span>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key !== "" ? (
                        <div id="printablediv">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    KYCs /{" "}
                                    <strong className="text-primary small">
                                      {this.state.company_title}
                                    </strong>
                                  </h3>
                                  <div className="nk-block-des text-soft">
                                    <ul className="list-inline">
                                      {/*<li>
                                        Application ID:{" "}
                                        <span className="text-base">
                                          KID000844
                                        </span>
                      </li>*/}
                                      <li>
                                        Submited At:{" "}
                                        <span className="text-base">
                                          {this.state.client_register_date}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="btn-group">
                                    {this.state.completed_percentage < 100 ? (
                                      <button
                                        onClick={() => {
                                          this.email_kyc_Details();
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                        disabled={this.state.disabled}
                                      >
                                        <em className="icon ni ni-emails"></em>
                                        <span>Send Email</span>
                                      </button>
                                    ) : null}
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.print_details();
                                      }}
                                      className="btn btn-outline-primary"
                                    >
                                      <em className="icon ni ni-printer"></em>
                                      <span>Print</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="nk-block-head">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title title">
                                  Profile Completed{" "}
                                  <span className="text-primary">
                                    ({this.state.completed_percentage}%)
                                  </span>
                                </h5>
                                <div className="card card-bordered card-preview">
                                  <div className="card-inner">
                                    <div className="progress progress-lg">
                                      <div
                                        className="progress-bar"
                                        data-progress={
                                          this.state.completed_percentage
                                        }
                                        style={{
                                          width: `${this.state.completed_percentage}%`,
                                        }}
                                      >
                                        {this.state.completed_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.kyc_details &&
                            this.state.kyc_details.length > 0 ? (
                            <div id="accordion" className="accordion">
                              {this.state.kyc_details.map((kyc, i) => (
                                <div
                                  className="accordion-item"
                                  key={`kycid${i}`}
                                >
                                  <a
                                    href="#"
                                    className={this.handle_accordion_head(i)}
                                    data-toggle="collapse"
                                    data-target={`#accordion-item-${i}`}
                                  >
                                    <h6 className="title">
                                      {kyc.steps_information.step_title}
                                      <br />
                                      <small>
                                        {kyc.steps_information.step_description}
                                      </small>
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className={this.handle_accordion_body(i)}
                                    id={`accordion-item-${i}`}
                                    data-parent="#accordion"
                                  >
                                    <div className="accordion-inner">
                                      {kyc.steps_data &&
                                        kyc.steps_data.length > 0 ? (
                                        <div className="card">
                                          <ul className="data-list is-compact">
                                            {kyc.steps_data.map(
                                              (kyc_steps, i) => (
                                                <li
                                                  className="data-item"
                                                  key={`step${i}`}
                                                >
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      {kyc_steps.field_name}
                                                    </div>
                                                    <div className="data-value">
                                                      {kyc_steps.field_type ===
                                                        "image" ? (
                                                        <img
                                                          src={
                                                            kyc_steps.field_value
                                                          }
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        />
                                                      ) : (
                                                        kyc_steps.field_value
                                                      )}
                                                    </div>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="col-lg-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <ul class="nav nav-tabs">
                              <li class="nav-item ">
                                <a
                                  class={`nav-link ${this.state.sub_activeTab === "tabItem8"
                                    ? "active"
                                    : ""
                                    }`}
                                  data-toggle="tab"
                                  href="#tabItem8"
                                  onClick={() =>
                                    this.handleTabClick("tabItem8")
                                  }
                                >
                                  <em class="icon ni ni-users"></em>
                                  <span>
                                    {
                                      localization.clientsListing
                                        .allClientsTabSub
                                    }
                                  </span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${this.state.sub_activeTab === "tabItem9"
                                    ? "active"
                                    : ""
                                    }`}
                                  data-toggle="tab"
                                  href="#tabItem9"
                                  onClick={() =>
                                    this.handleTabClick("tabItem9")
                                  }
                                >
                                  <em class="icon ni ni-user-list"></em>
                                  <span>
                                    {
                                      localization.clientsListing
                                        .pendingInvitationsSub
                                    }
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <div class="tab-content" style={{ marginTop: 0 }}>
                              <div
                                class={`tab-pane ${this.state.sub_activeTab === "tabItem8"
                                  ? "active"
                                  : ""
                                  }`}
                                id="tabItem8"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={this.state.columnServices}
                                        tableData={this.state.tableData}
                                        title="All Sub Clients"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                class={`tab-pane ${this.state.sub_activeTab === "tabItem9"
                                  ? "active"
                                  : ""
                                  }`}
                                id="tabItem9"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={
                                          this.state
                                            .columnServices_invitedClients
                                        }
                                        tableData={
                                          this.state.tableData_invitedClients
                                        }
                                        title={
                                          localization.clientsListing
                                            .pendingInvitationsSub
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Role Sub Client Assignment Modal START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalSubClientAssignRole"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-grid-alt"></em>Sub
                              Client Roles{" "}
                              <sup>
                                <small className="text-soft">
                                  {this.state.rolesSubClientName}
                                </small>
                              </sup>
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body text-left">
                          <div className="nk-modal">
                            <div className="nk-modal-text">
                              {this.state.subclientRoleLoader === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.errorMessageSubClientRole !==
                                    "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>
                                            {
                                              this.state
                                                .errorMessageSubClientRole
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.successMessageSubClientRole !==
                                    "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {
                                              this.state
                                                .successMessageSubClientRole
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="card card-bordered card-preview">
                                    <table className="table table-orders">
                                      <thead className="tb-odr-head">
                                        <tr className="tb-odr-item">
                                          <th
                                            width="35%"
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                            }}
                                          >
                                            <span className="tb-odr-action">
                                              Roles
                                            </span>
                                          </th>
                                          <th width="65%">
                                            <span className="tb-odr-action">
                                              Sub Roles
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="tb-odr-body">
                                        {this.state.allRoleData &&
                                          this.state.allRoleData.length > 0 ? (
                                          <>
                                            {this.state.allRoleData.map(
                                              (role, index) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`role${index}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.allRoleData.some(
                                                            (item) =>
                                                              item.alt ===
                                                              role.alt &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .changedAllRolesRights
                                                          }
                                                          disabled={
                                                            role.seqno === 1 &&
                                                            this.state.assignRoleData.some(
                                                              (subRole) =>
                                                                role.alt ===
                                                                subRole.alt
                                                            )
                                                          }
                                                          name={role.id.toString()}
                                                          id={`role-${role.parent_role_id}`}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={role.alt}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    {this.state.allSubRoleData
                                                      .filter((subRole) => {
                                                        return (
                                                          subRole.role_id ===
                                                          role.id
                                                        );
                                                      })
                                                      .map(
                                                        (subRole, subIndex) => (
                                                          <div
                                                            key={`subRole${subIndex}`}
                                                          >
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={this.state.allSubRoleData.some(
                                                                    (item) =>
                                                                      item.alt ===
                                                                      subRole.alt &&
                                                                      item.isChecked
                                                                  )}
                                                                  onChange={
                                                                    this
                                                                      .changedAllSubRolesRights
                                                                  }
                                                                  name={subRole?.tabdetailid?.toString()}
                                                                  color="primary"
                                                                />
                                                              }
                                                              style={{
                                                                marginBottom:
                                                                  "0px",
                                                              }}
                                                              label={
                                                                subRole.alt
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="nk-modal-action  text-right">
                                    <button
                                      href="#"
                                      className="btn btn-lg btn-mw btn-primary "
                                      onClick={() => {
                                        this.handleSubmitRoleCheckboxSub(
                                          this.state.checkedName,
                                          this.state.checkedEmail
                                        );
                                      }}
                                      disabled={this.state.subdisableRoleBtn}
                                    >
                                      Assign Roles
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Role Sub Client Assignment Modal End */}

                  {/* Integration Permission Modal START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalAgentAssignRole"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-exchange"></em>
                              Manage Integration{" "}
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body text-left">
                          <div className="nk-modal">
                            <div className="nk-modal-text">
                              <>
                                {this.state.errorMessage !== "" ? (
                                  <div
                                    className="example-alert "
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div
                                    className="example-alert "
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card card-bordered card-preview">
                                  <table className="table table-sm">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th
                                          width="25%"
                                          style={{
                                            borderRight: "1px solid #dbdfea",
                                            textAlign: "center",
                                            padding: "15px",
                                          }}
                                        >
                                          <span className="tb-odr-action">
                                            Enable/Disable
                                          </span>
                                        </th>
                                        <th
                                          width="50%"
                                          style={{
                                            borderRight: "1px solid #dbdfea",
                                            textAlign: "center",
                                            padding: "15px",
                                          }}
                                        >
                                          <span className="tb-odr-action">
                                            Title
                                          </span>
                                        </th>
                                        <th
                                          width="25%"
                                          style={{
                                            textAlign: "center",
                                            padding: "15px",
                                          }}
                                        >
                                          <span className="tb-odr-action">
                                            Save Changes
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.va_intergation === "Yes" ? (
                                        <tr>
                                          <td
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                              textAlign: "center",
                                              padding: "15px",
                                            }}
                                          >
                                            <div className="custom-control custom-checkbox">
                                              <div className="form-group">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="customCheckPermission"
                                                  checked={
                                                    this.state.isEnableChecked
                                                  }
                                                  onChange={
                                                    this.handleEnableChange
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="customCheckPermission"
                                                ></label>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                              textAlign: "center",
                                              padding: "15px",
                                            }}
                                          >
                                            VoiceAlerts Dashboard for{" "}
                                            {this.state.client_name} (
                                            {this.state.client_comp})
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ padding: "15px" }}
                                          >
                                            <button
                                              href="#"
                                              className="btn btn-sm btn-mw btn-primary"
                                              onClick={() => {
                                                this.updateSubClientPremission();
                                              }}
                                              disabled={
                                                this.state.disableRoleBtn
                                              }
                                            >
                                              Save Changes
                                            </button>
                                          </td>
                                        </tr>
                                      ) : null}

                                      {this.state.meta_intergation === "Yes" ? (
                                        <tr>
                                          <td
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                              textAlign: "center",
                                              padding: "15px",
                                            }}
                                          >
                                            <div className="custom-control custom-checkbox">
                                              <div className="form-group">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="customCheckPermissionMeta"
                                                  checked={
                                                    this.state
                                                      .isEnableCheckedMeta
                                                  }
                                                  onChange={
                                                    this.handleEnableChangeMeta
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="customCheckPermissionMeta"
                                                ></label>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                              textAlign: "center",
                                              padding: "15px",
                                            }}
                                          >
                                            Metabase Integration for{" "}
                                            {this.state.client_name} (
                                            {this.state.client_comp})
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ padding: "15px" }}
                                          >
                                            <button
                                              href="#"
                                              className="btn btn-sm btn-mw btn-primary"
                                              onClick={() => {
                                                this.updateSubClientPremissionMeta();
                                              }}
                                              disabled={
                                                this.state.disableRoleBtn
                                              }
                                            >
                                              Save Changes
                                            </button>
                                          </td>
                                        </tr>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Integration Permission Modal End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalAddNewClient"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Add New Sub Client
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.clientErrorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.clientErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.clientSuccessMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.clientSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="main_client_name"
                                      name="main_client_name"
                                      type="text"
                                      label="Main Client"
                                      value={this.state.main_client_name}
                                      inputProps={{
                                        maxLength: 64,
                                        readOnly: true,
                                      }}
                                      disabled
                                      helperText="Main client of the invite recipient."
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="client_name"
                                      name="client_name"
                                      type="text"
                                      label="Full Name"
                                      defaultValue={this.state.client_name}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 ]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Full name of the invite recipient."
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="client_email"
                                      name="client_email"
                                      type="text"
                                      label="Email Address"
                                      defaultValue={this.state.client_email}
                                      helperText="Email address of the invite recipient."
                                      inputProps={{ maxLength: 100 }}
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <TextareaAutosize
                                      maxrows={4}
                                      name="invitation_comment"
                                      id="invitation_comment"
                                      aria-label=""
                                      placeholder="Add Comment *"
                                      inputProps={{ maxLength: 500 }}
                                      //maxLength={500}
                                      value={this.state.invitation_comment}
                                      helperText="Maximum 500 characters"
                                      onChange={this.handleChange}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        borderColor: "rgba(0, 0, 0, 0.125)",
                                        borderRadius: "4px",
                                        padding: "5px",
                                      }}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.saveNewClient()}
                                  className="btn btn-primary"
                                  disabled={this.state.disabled}
                                >
                                  <span>Send Invite</span>{" "}
                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        An email will be dispatched to the
                                        provided email address.
                                      </li>
                                      <li>
                                        This email will contain a secure,
                                        tokenized link for client registration.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                  {/* Modal Invitation Comment */}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalInvitationComment"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Invitation Comments</h5>
                        </div>
                        <div class="modal-body">
                          <p className="text-dark">
                            {this.state.invitation_comment &&
                              this.state.invitation_comment !== ""
                              ? this.state.invitation_comment
                              : "No Comments"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Modal Invitation Comment */}
                  {/* Resend Invitation Modal Start */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalresendInvitation"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        {this.state.clientSuccessMessage && (
                          <>
                            <a
                              style={{ cursor: "pointer" }}
                              className="close"
                              onClick={() => {
                                this.modalHideResend();
                              }}
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal">
                                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                                <h4
                                  className="nk-modal-title"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {this.state.clientSuccessMessage}
                                </h4>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.clientErrorMessage && (
                          <>
                            <a
                              style={{ cursor: "pointer" }}
                              className="close"
                              onClick={() => {
                                this.modalHideResend();
                              }}
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal">
                                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                <h4
                                  className="nk-modal-title"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {this.state.clientErrorMessage}
                                </h4>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Resend Invitation Modal Ends */}
                  {/* MODAL Delete Client START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlert"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDel();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                            <h4 className="nk-modal-title">
                              Delete Invited Client?
                            </h4>
                            {this.state.clientErrorMessage !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.clientErrorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.clientSuccessMessage !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.clientSuccessMessage}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Are you sure you want to delete this Invited
                                Client?
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDel();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <button
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteInviteClient();
                                }}
                                disabled={this.state.disabled}
                              >
                                DELETE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* MODAL Delete Client End */}
                  {/**** Open SSO Modal ***/}
                  <div className="modal fade" tabindex="-1" id="modalDefaultLogin">
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-user-list"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Login to the Sub Client
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.loginErrorMessage === "" ? (
                            <div className="alert alert-pro alert-primary">
                              <div className="alert-text">
                                <h6>Connecting.....</h6>
                                <p>
                                  Please wait while we are connecting to the sub client
                                  Login. You will be redirected to the Sub Client dashboard
                                  after the authentication.
                                </p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.loginErrorMessage !== "" ? (
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h6>Login Error</h6>
                                <p>{this.state.loginErrorMessage}</p>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Close SSO Modal */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subclients);
